import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


function Slider({ images = [], height }) {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2500}
    >
      {images.length > 0 ? (
        images.map((img, index) => (
          <div key={index}>
            <img
              src={img}
              alt={`Image ${index + 1}`}
              className={`w-full object-cover object-center ${height}`}
            />
          </div>
        ))
      ) : (
        <div>
          <p>No images available</p>
        </div>
      )}
    </Carousel>
  );
}

export default Slider;
