// import React , {useState , useEffect} from 'react'
// import VehicleCards from '../Common/VehicleCards'
// import axios from 'axios';

// function CarRental() {

//   const [carsState , setCarsState] = useState([]);

//   // Define an async function to fetch data
//   const getData = async () => {
//     try {
//       const response = await axios.get("https://api.travoolah.com/api/Transportation");
//       // Assuming the data is an array, set it directly
//       setCarsState(response.data.$values);
//     } catch (error) {
//       console.error("Error fetching adventure data:", error);
//     }
//   };
  
//     useEffect(() => {
//       getData();
//     }, []);

//     console.log("cars",carsState)  

//   return (
//     <div className='flex justify-center px-5 mt-20'>
//         <div className='w-10/12 flex flex-col gap-y-5'>
//             <span className='mx-auto'>
//                 <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Car Rentals</h3>
//             </span>
//             <div className='mx-auto overflow-x-auto md:grid md:grid-cols-3 md:gap-x-5 lg:gap-x-10 flex flex-col gap-y-5 px-3 py-5'>
//                 {/* <VehicleCards img={Lalit} title={"The Lalit Grand Palace"} AirCond={"Gupkar Rd, Srinagar, J&K 190001"} seats={"7999"} LuggageSp={"4999/Night"}/> */}
//                 {carsState.length > 0 ? carsState
//               .filter((event) => event.transportationCategoryId=="3"
//             ) // Filter by category name
//               .map((event, index) => (
//                 <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
//                   <VehicleCards 
//                     img={`https://api.travoolah.com/${event.image}`} // Adjust the image path if necessary
//                     title={event.title} 
//                     seats={event.seats} 
//                     LuggageSp={event.space} // Update logic for pricing as needed
//                   />
//                 </div>
//               )):  <p>No data avalible</p>}
//             </div>
//         </div>
//     </div>
//   )
// }

// export default CarRental

// ok code with heading not good
import React, { useState, useEffect } from 'react';
import VehicleCards from '../Common/VehicleCards';
import axios from 'axios';

function CarRental() {
  const [carsState, setCarsState] = useState([]);

  // Fetch data from API
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Transportation");
      setCarsState(response.data.$values || []);
    } catch (error) {
      console.error("Error fetching car rental data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // console.log("cars", carsState);
  return (
    <div className="flex justify-center px-4 sm:px-5  sm:mt-20  ">
      <div className="w-full max-w-6xl flex flex-col gap-y-5">
        <h3 className="text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5 ">
          Car Rentals
        </h3>
        <div className="relative py-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {carsState.length > 0 ? (
              carsState
              .filter((event) => event.transportationCategoryId=="3") .map((event, index) => (
                <div key={index} className="w-full">
                  <VehicleCards
                    img={`https://api.travoolah.com/${event.image}`}
                    title={event.title}
                    seats={event.seats}
                    LuggageSp={event.space}
                  />
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarRental;
