import React from 'react'
import { Link } from "react-router-dom";

function LeftNav({setIsNavVisible}) {
    return (
        <div className="col-span-1 w-full flex flex-col  bg-gradient-to-r from-[#EAAC83] to-[#E06914] hover:transition-all hover:duration-300 border border-green-500">
          
          <div className="pt-5 mx-3 text-xl ">
            <div className="text-[#DDDDDD] text-xl ">
              <p className='font-extrabold text-gray-800'>Admin</p>
              <p className='font-extrabold text-gray-800'>Welcome Back!</p>
            </div>
          </div>
          <div>
            <ul  className="gap-y-4 grid grid-cols-3 md:grid-cols-1 lg:grid-cols-1 px-0 md:px-2 lg:px-3  py-10 group text-white">
            {/* newRoutes */}
             <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/hotels">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Hotels
                  </span>
                </Link>
              </li>

              {/* service admins */}
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/hotelservices">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Add Hotel Services
                  </span>
                </Link>
              </li>
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
              <Link to="/admin/hotelroom">
                <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                  HotelRoom
                </span>
              </Link>
            </li>
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/villas">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Villas
                  </span>
                </Link>
              </li>

              {/* rest */}
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/travel">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Travel Package
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/itenaries">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Travelpackage Itenaries
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/transport">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Transportation
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/shoots">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Shoots
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/adventure">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Adventure
                  </span>
                </Link>
              </li>
              
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/event">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Event Managament
                  </span>
                </Link>
              </li>

              
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/addons">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Add EventAddons
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/eventDayWize">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Add Eventitenarise
                  </span>
                </Link>
              </li>

              
              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/packageInclude">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Add Eventincludes
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/wellness">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Wellness
                  </span>
                </Link>
              </li>

              <li className="hover:text-black cursor-pointer hover:transition-all  duration-500 ease-in-out hover:translate-x-2">
                <Link to="/admin/travelAi">
                  <span className="flex items-center gap-2 text-sm md:text-lg lg:text-xl px-2 py-4 rounded-l-lg" onClick={() => setIsNavVisible(false)}>
                    Travel Ai
                  </span>
                </Link>
              </li>
              
            </ul>
          </div>
        </div>
      );
}

export default LeftNav