import React from 'react'
import WellnessRelaxationTopSec from '../Components/WellnessRelaxation/WellnessRelaxationTopSec'
import WellnessRetreat from '../Components/WellnessRelaxation/WellnessRetreat'
import NatureEmersion from "../Components/WellnessRelaxation/NatureEmersion"
import YogaRelaxation from '../Components/WellnessRelaxation/YogaRelaxation'
import GeneralOutdoor from '../Components/WellnessRelaxation/GeneralOutdoor'
import { useGSAP } from '@gsap/react'
import { animateWithGsap } from '../Utils/gsapAnimations'
function WellnessPage() {
  useGSAP(()=>{
    animateWithGsap(".mainWellnesSection",1.5,0.5);
  },[])
  return (

    <div className="w-full overflow-x-hidden mainWellnesSection">
        <WellnessRelaxationTopSec />
        <WellnessRetreat />
        <NatureEmersion />
        <YogaRelaxation />
        <GeneralOutdoor />
    </div>
  )
}

export default WellnessPage