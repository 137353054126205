import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import WellnessCard from '../Common/WellnessCard';

function WellnessRetreat() {
  const [wellnessRetreat, setWellnessRetreat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://api.travoolah.com/api/Relaxation");
        // console.log("API response:", response.data);
        setWellnessRetreat(response.data.$values || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching wellness retreat data:", error);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    getData();
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/wellness/${id}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const filteredRetreats = wellnessRetreat.filter(
    (retreat) => retreat.relaxationCategoryId== "3"

  );

  // console.log("wellnessRetreat",wellnessRetreat)

  return (
    <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
      <div className='w-full max-w-6xl flex flex-col gap-y-5'>
        <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>
          Wellness Retreat Packages
        </h3>
        <div className='relative py-8'>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
            {filteredRetreats.length > 0 ? (
              filteredRetreats.map((retreat, index) => (
                <div key={index} className='w-full'>
                  <WellnessCard
                    img={
                      retreat.images?.$values?.length > 0 
                        ? `https://api.travoolah.com/${retreat.images.$values[0]}`
                        : '/placeholder-image.jpg' // Fallback to placeholder
                    }
                    title={retreat.title}
                    duration={retreat.duration}
                    oldPrice={`${retreat.price + 1500}`}
                    newPrice={retreat.price}
                    address={retreat.description}
                    onClick={() => handleCardClick(retreat.id)}
                  />
                </div>
              ))
            ) : (
              <p>No wellness retreat packages available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WellnessRetreat;
