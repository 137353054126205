// import './App.css';
// import Navbar from './Components/Common/Navbar';
// import Homepage from './Pages/Homepage';
// import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
// import Login from './Pages/Login';
// import SignUp from './Pages/SignUp';
// import TravelPackagesPage from './Pages/TravelPackagesPage';
// import TransportPage from './Pages/TransportPage';
// import PhotoShoots from './Pages/PhotoShoots';
// import Footer from './Components/Common/Footer';
// import EventManagementPage from './Pages/EventManagementPage';
// import AdventrePage from './Pages/AdventrePage';
// import WellnessPage from './Pages/WellnessPage';
// import AdminRoutes from './Pages/Admin/AdminRoutes'
// import { RecoilRoot } from "recoil";
// import TermsandConditions from './Components/Common/TermsandConditions';
// import PrivacyPolicy from './Components/Common/PrivacyPolicy';
// import HotelsPage from './Pages/HotelsPage';
// import Villas from './Pages/Villas';
// import Contact from './Pages/Contact';
// import HotelDetailsPage from './Pages/HotelDetailsPage';
// import VillasDetail from './Pages/VillasDetail';
// import TravelDetailPage from "./Pages/TravelDetailPage"
// import AdventureDetailsPage from './Pages/AdventureDetailsPage';
// import PhotoShootDetailPage from './Pages/PhotoShootDetailPage';
// import EventDetailPage from './Pages/EventDetailPage';
// import WellnessDetailsPage from './Pages/WellnessDetailsPage';
// import ContactFormAdventure from "./Pages/Contact"
// import ContactForm from './Pages/HotelContact';
// import PartnersPage from './Pages/PartnersPage';
// import gsap from 'gsap';
// import { useEffect, useState } from 'react';
// import HomepageLoader from './Pages/HomepageLoader';

// function App() {
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate loading time (e.g., 2 seconds)
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 2000); // Adjust time as needed

//     // Cleanup timer on component unmount
//     return () => clearTimeout(timer);
//   }, [location]);

//   //useEffect for animations
//   useEffect(() => {
//     if (!loading) {
//       gsap.fromTo('#homeSection', { x: '-100%', opacity: 0 }, { x: 0, opacity: 1, duration: 1.5, delay: 0.5 });
//     }
//   }, [loading, location]);

//   return (
//     <RecoilRoot>
//       <div className='scroll-smooth'>
//         <Navbar />
//         {loading ? (
//           <HomepageLoader /> //for loading
//         ) : (
          
//           <Routes location={location} key={location.pathname}>
//             <Route path="/" element={<Homepage />} />
//             <Route path='/hotels' element={<HotelsPage />} />
//             <Route path='/hotel/:id' element={<HotelDetailsPage />} />
//             <Route path='/villas' element={<Villas />} />
//             <Route path='/villas/:id' element={<VillasDetail />} />
//             <Route path="/travel-packages" element={<TravelPackagesPage />} />
//             <Route path='/travel-package/:id' element={<TravelDetailPage />} />
//             <Route path='/transportation' element={<TransportPage />} />
//             <Route path='/adventure' element={<AdventrePage />} />
//             <Route path='/adventure/:id' element={<AdventureDetailsPage />} />
//             <Route path='/photoShoots' element={<PhotoShoots />} />
//             <Route path='/photoShoots/:id' element={<PhotoShootDetailPage />} />
//             <Route path='/eventManagement' element={<EventManagementPage />} />
//             <Route path='/eventManagement/:id' element={<EventDetailPage />} />
//             <Route path='/wellness' element={<WellnessPage />} />
//             <Route path='/wellness/:id' element={<WellnessDetailsPage />} />
//             <Route path='/terms-conditions' element={<TermsandConditions />} />
//             <Route path='/privacy-policy' element={<PrivacyPolicy />} />
//             <Route path='/contact' element={<Contact />} />
//             <Route path='/contact-form-adventure/:id' element={<ContactFormAdventure />} />
//             <Route path="/contact-form/:id" element={<ContactForm />} />
//             <Route path="/admin/login" element={<Login />} />
//             <Route path="/admin/signUp" element={<SignUp />} />
//             <Route path='/partnersContact' element={<PartnersPage />} />
//             <Route path="/admin/*" element={<AdminRoutes />} />
//           </Routes>
//         )}
//         <Footer />
//       </div>
//     </RecoilRoot>
//   );
// }

// export default App;




// help
import './App.css';
import Navbar from './Components/Common/Navbar';
import Homepage from './Pages/Homepage';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import TravelPackagesPage from './Pages/TravelPackagesPage';
import TransportPage from './Pages/TransportPage';
import PhotoShoots from './Pages/PhotoShoots';
import Footer from './Components/Common/Footer';
import EventManagementPage from './Pages/EventManagementPage';
import AdventrePage from './Pages/AdventrePage';
import WellnessPage from './Pages/WellnessPage';
import AdminRoutes from './Pages/Admin/AdminRoutes'
import { RecoilRoot} from "recoil";
import TermsandConditions from './Components/Common/TermsandConditions';
import PrivacyPolicy from './Components/Common/PrivacyPolicy';
import HotelsPage from './Pages/HotelsPage';
import Villas from './Pages/Villas';
import Contact from './Pages/Contact';
import HotelDetailsPage from './Pages/HotelDetailsPage';
import VillasDetail from './Pages/VillasDetail';
import TravelDetailPage from "./Pages/TravelDetailPage"
import AdventureDetailsPage from './Pages/AdventureDetailsPage';
import PhotoShootDetailPage from './Pages/PhotoShootDetailPage';
import EventDetailPage from './Pages/EventDetailPage';
import WellnessDetailsPage from './Pages/WellnessDetailsPage';
import ContactFormAdventure from "./Pages/Contact"
import ContactForm from './Pages/HotelContact';
import PartnersPage from './Pages/PartnersPage';
import About from './Pages/About';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <div className='scroll-smooth'>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path='/hotels' element={<HotelsPage/>}/>
            <Route path='/hotel/:id' element={<HotelDetailsPage />} />
            <Route path='/villas' element={<Villas />} />
            <Route path='/villas/:id' element={<VillasDetail />} />
            <Route path="/travel-packages" element={<TravelPackagesPage />} />
            <Route path='/travel-package/:id' element={<TravelDetailPage />} />
            <Route path='/transportation' element={<TransportPage />} />
            <Route path='/adventure' element={<AdventrePage />} />
            <Route path='/adventure/:id' element={<AdventureDetailsPage />} />
            <Route path='/photoShoots' element={<PhotoShoots />} />
            <Route path='/photoShoots/:id' element={ <PhotoShootDetailPage/>} />
            <Route path='/eventManagement' element={<EventManagementPage />} />
            <Route path='/eventManagement/:id' element={<EventDetailPage />} />
            <Route path='/wellness' element={<WellnessPage />} />
            <Route path='/wellness/:id' element={<WellnessDetailsPage/>} />
            <Route path='/terms-conditions' element={<TermsandConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
            {/* normal contact form below */}
            <Route path='/contact' element={<Contact/>} /> 
            <Route path='/About' element={<About/>} /> 
            <Route path='/contact-form-adventure/:id' element={<ContactFormAdventure />} />
            {/* contact form for hotels */}
            <Route path="/contact-form/:id" element={<ContactForm />} />
            {/* admin routes */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/signUp" element={<SignUp />} />
            <Route path='/partnersContact' element={<PartnersPage />} />
            {/* Protected route for Admin Dashboard */}
            <Route
              path="/admin/*"
              element={<AdminRoutes />}
            />
          </Routes>
          <Footer />
        </div>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;