import React, { useState, useEffect } from "react"; 
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from "lucide-react";

function HotelRoomAdmin() {
  const [rooms, setRooms] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [roomForm, setRoomForm] = useState({
    id: null, // For editing rooms
    name: "",
    capacity: "",
    pricePerNight: "",
    images: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch hotels
  const fetchHotels = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get("https://api.travoolah.com/api/Hotel");
      const hotelData = response.data?.$values || [];
      // console.log("hotel ", hotelData);
      setHotels(hotelData);
    } catch (error) {
      setError("Error fetching hotels.");
      console.error("Error fetching hotels:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch rooms for the selected hotel
  const fetchRooms = async () => {
    if (!selectedHotel) {
      setRooms([]);
      return;
    }
    setLoading(true);
    setError("");
    try {
      const selectedHotelData = hotels.find((hotel) => hotel.id === parseInt(selectedHotel));
      const hotelRooms = selectedHotelData?.hotelRooms?.$values || [];
      setRooms(hotelRooms);
    } catch (error) {
      setError("Error fetching rooms.");
      console.error("Error fetching rooms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  useEffect(() => {
    fetchRooms();
  }, [selectedHotel]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file input changes (Multiple images)
  const handleFileChange = (e) => {
    setRoomForm((prev) => ({ ...prev, images: e.target.files }));
  };

  // Handle hotel selection
  const handleHotelChange = (e) => {
    setSelectedHotel(e.target.value);
    setRoomForm({ id: null, name: "", capacity: "", pricePerNight: "", images: [] });
    setSuccessMessage("");
    setError("");
  };

  // Handle form submission for both adding and updating
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedHotel) {
      alert("Please select a hotel first.");
      return;
    }

    if (!roomForm.name || !roomForm.capacity || !roomForm.pricePerNight || roomForm.images.length === 0) {
      alert("Please fill out all fields and upload at least one image.");
      return;
    }

    if (isNaN(roomForm.pricePerNight) || roomForm.pricePerNight <= 0) {
      alert("Please enter a valid price.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("name", roomForm.name);
      formData.append("capacity", roomForm.capacity);
      formData.append("pricePerNight", roomForm.pricePerNight);
      formData.append("hotelId", selectedHotel);
      
      // Append images
      for (let i = 0; i < roomForm.images.length; i++) {
        formData.append("images", roomForm.images[i]);
      }

      if (roomForm.id) {
        // Update existing room
        console.log("Updating room with ID:", roomForm.id);
        await axios.put(`https://api.travoolah.com/api/HotelRooms/${roomForm.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        // Create new room
        await axios.post("https://api.travoolah.com/api/HotelRooms", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      // Reset form after successful submission
      resetForm();
      fetchRooms();
      setSuccessMessage(roomForm.id ? "Room updated successfully!" : "Room added successfully!");
    } catch (error) {
      setError("Error submitting room.");
      console.error("Error submitting room:", error);
    } finally {
      setLoading(false);
    }
  };

  // Edit room
  const handleEdit = (room) => {
    setRoomForm({
      id: room.id,
      name: room.name,
      capacity: room.capacity,
      pricePerNight: room.pricePerNight,
      images: [], // File upload will be handled separately
    });
    setSuccessMessage(""); // Clear success message when editing
    setError(""); // Clear error message when editing
  };

  // Delete room
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this room?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/HotelRooms/${id}`);
        fetchRooms();
        setSuccessMessage("Room deleted successfully!");
      } catch (error) {
        setError("Error deleting room.");
        console.error("Error deleting room:", error);
      }
    }
  };

  // Reset form
  const resetForm = () => {
    setRoomForm({
      id: null,
      name: "",
      capacity: "",
      pricePerNight: "",
      images: [],
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Hotel Rooms</h2>

      {/* Success/Error Message */}
      {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
      {error && <p className="text-red-600 mb-4">{error}</p>}

      {/* Hotel Dropdown */}
      <div className="mb-6">
        <label htmlFor="hotel-select" className="block text-gray-700 font-medium mb-2">
          Select Hotel
        </label>
        <select
          id="hotel-select"
          value={selectedHotel}
          onChange={handleHotelChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select a Hotel --</option>
          {hotels && hotels.length > 0 ? (
            hotels.map((hotel) => (
              <option key={hotel.id} value={hotel.id}>
                {hotel.title}
              </option>
            ))
          ) : (
            <option disabled>No hotels available</option>
          )}
        </select>
      </div>

      {/* Room Form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="name"
          value={roomForm.name}
          onChange={handleChange}
          placeholder="Room Name"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="text"
          name="capacity"
          value={roomForm.capacity}
          onChange={handleChange}
          placeholder="Capacity"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="number"
          name="pricePerNight"
          value={roomForm.pricePerNight}
          onChange={handleChange}
          placeholder="Price Per Night"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="file"
          name="images"
          onChange={handleFileChange}
          multiple
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700"
        >
          {roomForm.id ? "Update Room" : "Add Room"}
        </button>
        <button
          type="button"
          onClick={resetForm}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
        >
          Reset
        </button>
      </form>

      {/* Rooms List */}
      <div className="mt-8">
        {rooms.map((room) => (
          <div
            key={room.id}
            className="flex justify-between items-center p-4 border border-gray-300 rounded-md mb-4"
          >
            <div>
              <h3 className="font-bold text-gray-800">{room.name}</h3>
              <p className="text-gray-600">Capacity: {room.capacity}</p>
              <p className="text-gray-600">Price: ${room.pricePerNight}</p>
            </div>
            <div className="flex space-x-2">
      {/*<button
        onClick={() => handleEdit(room)}
        className="text-yellow-600 hover:text-yellow-800"
      >
        <Edit2 size={20} />
      </button>*/}
              <button
                onClick={() => handleDelete(room.id)}
                className="text-red-600 hover:text-red-800"
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HotelRoomAdmin;
