import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import AdventureCard from '../Common/AdventureCard';

function AdventureActivities() {

  const [adventure, setAdventure] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Adventure");
      // Assuming the data is an array, set it directly
      // console.log("adventure ",response)
      setAdventure(Array.isArray(response.data.$values) ? response.data.$values : []);
      // console.log("Adven ",adventure)
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/adventure/${id}`)
  }

  console.log("adventure ", adventure)

  return (
    <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
      <div className='w-full max-w-6xl flex flex-col gap-y-5'>
        <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>Adventure Activities</h3>
        <div className='relative'>
          <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x'>
            {adventure.length > 0 ? adventure
              .filter((adventure) => adventure.adventureCategoryId=="1"
            ) // Filter by category id if needed
              .map((adventure, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  {/* <AdventureCard 
                    img={adventure.images && adventure.images.length > 0 ? `https://api.travoolah.com/${adventure.images[0]}` : '/placeholder-image.jpg'}
                    title={adventure.title} 
                    location={ adventure.location}
                    oldPrice={adventure.price} 
                    newPrice={adventure.price} // Update as per logic
                    onClick={()=>{
                      handleCardClick(adventure.id)
                    }}
                  /> */}
                  <AdventureCard
                    img={adventure.images.$values && adventure.images.$values.length > 0
                      ? `https://api.travoolah.com/${adventure.images.$values[0]}`
                      : '/placeholder-image.jpg'} // Fallback to placeholder
                    title={adventure.title}
                    location={adventure.location}
                    oldPrice={adventure.price}
                    newPrice={adventure.price}
                    onClick={() => {
                      handleCardClick(adventure.id)
                    }}
                  />
                </div>
              )) : <p>No data avalible</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdventureActivities