import React from "react";

const About = () => {
  return (
    <div className="bg-gradient-to-b from-gray-100 to-white py-16 px-8 lg:px-24 pt-[7rem]">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-800">
          About Us - Travoolah
        </h1>
        <p className="mt-4 text-lg md:text-xl text-gray-600">
          Discover the heart of Kashmir with your trusted travel companion.
        </p>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto space-y-12">
        {/* Welcome Section */}
        <section>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Welcome to Travoolah</h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Welcome to <span className="font-semibold italic">Travoolah</span>, 
            your trusted travel companion based in the heart of Srinagar, Jammu & Kashmir.
            We are more than just a travel agency – we are storytellers, explorers, and your
            partners in discovering the unparalleled beauty of Kashmir and beyond.
          </p>
        </section>

        {/* Mission Section */}
        <section>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Mission</h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            To craft unforgettable journeys that inspire, rejuvenate, and connect people with
            the beauty of nature, culture, and adventure.
          </p>
        </section>

        {/* Why Choose Us Section */}
        <section>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Why Choose Us?</h2>
          <ul className="list-disc ml-8 space-y-4 text-gray-700 text-lg leading-relaxed">
            <li>
              <span className="font-semibold">Local Expertise:</span> Being based in Srinagar, we have 
              an intimate knowledge of Kashmir's hidden gems and iconic destinations.
            </li>
            <li>
              <span className="font-semibold">Tailored Experiences:</span> From romantic getaways to 
              adventurous treks, we design itineraries that suit your preferences and pace.
            </li>
            <li>
              <span className="font-semibold">Commitment to Excellence:</span> We prioritize your comfort, 
              safety, and satisfaction, ensuring a seamless travel experience.
            </li>
            <li>
              <span className="font-semibold">Eco-Friendly Practices:</span> We are dedicated to sustainable 
              tourism, preserving the pristine beauty of Kashmir for generations to come.
            </li>
          </ul>
        </section>

        {/* Services Section */}
        <section>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Services</h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            At Travoolah, we offer a wide range of services to make your journey unforgettable:
          </p>
          <ul className="list-disc ml-8 space-y-4 text-gray-700 text-lg leading-relaxed">
            <li>Personalized tour packages</li>
            <li>Accommodation and transportation</li>
            <li>Adventure activities</li>
            <li>Cultural and heritage experiences</li>
            <li>Professional travel assistance</li>
          </ul>
        </section>

        {/* Closing Section */}
        <section className="text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Discover the world with us. Discover Travoolah.
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed max-w-2xl mx-auto">
            Let Travoolah be your gateway to unforgettable memories. Together, let’s embark on a journey 
            where every moment is a treasure, and every destination feels like home.
          </p>
        </section>
      </div>
    </div>
  );
};

export default About;
