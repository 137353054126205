// import React, {useState , useEffect} from 'react'
// import axios from 'axios';
// import PhotoShootCard from '../Common/PhotoShootCard';
// import { useNavigate } from "react-router-dom";

// function PhotographyServices() {
//   const [shoots , setShootState] = useState([]);

//   // Define an async function to fetch data
//   const getData = async () => {
//     try {
//       const response = await axios.get("https://api.travoolah.com/api/Shoot");
//       // console.log("resp" , response);
//       setShootState(response.data.$values);
//     } catch (error) {
//       console.error("Error fetching adventure data:", error);
//     }
//   };
  
//     useEffect(() => {
//       getData();
//     }, []);

//     const navigate = useNavigate();

//     const handleCardClick = (id) => {
//         navigate(`/photoShoots/${id}`)
//     }

//     console.log("myShoots", shoots);
//   return (
//     <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
//       <div className='w-full max-w-6xl flex flex-col gap-y-5'>
//         <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>
//           Professional Photography Services
//         </h3>
//         <div className='relative py-8'>
//           <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x justify-start scroll-pl-4 '>
//         {/* <Card img={Lalit} title={"The Lalit Grand Palace"} address={"Gupkar Rd, Srinagar, J&K 190001"} oldPrice={"7999"} newPrice={"4999/Night"}/> */}
//         {shoots.length > 0 ? shoots
//               .filter((event) => event.shootCategory?.name === "professionalPhotographyServices") // Filter by category name
//               .map((event, index) => (
//                 <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
//                   <PhotoShootCard 
//                     img={event.images && event.images.$values.length > 0 ? `https://api.travoolah.com/${event.images.$values[0]}` : '/placeholder-image.jpg'} // Fallback to placeholder
//                     title={event.title} 
//                     duration={event.duration}
//                     oldPrice={`${event.price + 1500}`}
//                     newPrice={event.price} 
//                     address={event.description}
//                     onClick={()=>{
//                       handleCardClick(event.id)
//                     }}
//                   />
//                 </div>
//               )): <p>No data avalible</p>}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default PhotographyServices








import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhotoShootCard from '../Common/PhotoShootCard';
import { useNavigate } from "react-router-dom";

function PhotographyServices() {
  const [shoots, setShootState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Shoot");

      setShootState(response.data.$values || []);

      setShootState(response.data.$values);

    } catch (error) {
      console.error("Error fetching photography services data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/photoShoots/${id}`);
  };

  // console.log("myShoots", shoots);

  return (
    <div className="flex justify-center px-4 sm:px-5 mt-10 sm:mt-20">
      <div className="w-full max-w-6xl flex flex-col gap-y-5">
        <h3 className="text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5">
          Professional Photography Services
        </h3>
        <div className="relative py-8">
          {/* Use grid layout for responsiveness */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {shoots.length > 0 ? (
              shoots
                .filter((event) => event.shootCategoryId === 2) // Filter by shootCategoryId
                .map((event, index) => (
                  <div key={index} className="w-full">
                    <PhotoShootCard
                      img={
                        event.images?.$values?.length > 0
                          ? `https://api.travoolah.com/${event.images.$values[0]}`
                          : '/placeholder-image.jpg' // Fallback to placeholder
                      }
                      title={event.title}
                      duration={event.duration}
                      oldPrice={`${event.price + 1500}`}
                      newPrice={event.price}
                      address={event.description}
                      onClick={() => {
                        handleCardClick(event.id);
                      }}
                    />
                  </div>
                ))
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotographyServices;
