import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2, XCircle } from 'lucide-react';

function PhotographForm() {
  const [categories, setCategories] = useState([]);
  const [shoots, setShoots] = useState([]);
  const [shootForm, setShootForm] = useState({
    id: null,
    images: [],
    existingImages: [],
    title: '',
    price: '',
    additionalInfo: '',
    overview: '',
    termCondition: '',
    shootCategoryId: ''
  });
  const [errors, setErrors] = useState({});

  // Fetch categories and shoots
  useEffect(() => {
    fetchCategories();
    fetchShoots();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/ShootCategory');
      const categories = response.data.$values && response.data.$values ? response.data.$values : [];
      setCategories(categories);
    } catch (e) {
      console.error("Error fetching categories:", e);
    }
  };
  

  const fetchShoots = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/Shoot');
      setShoots(Array.isArray(response.data.$values)?response.data.$values:[]);
    } catch (e) {
      console.error("Error fetching shoots:", e);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShootForm({ ...shootForm, [name]: value });
  };

  // Handle multiple image uploads
  const handleImageChange = (e) => {
    setShootForm({ ...shootForm, images: Array.from(e.target.files) });
  };

  // Handle existing image deletion
  const handleImageDelete = (imagePath) => {
    setShootForm((prev) => ({
      ...prev,
      existingImages: prev.existingImages.filter((img) => img !== imagePath),
    }));
  };

  // Handle form submission
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setErrors({}); // Reset errors
  //   const formData = new FormData();

  //   if (shootForm.id) {
  //     formData.append("Id", shootForm.id);
  //   }
  //   formData.append("Title", shootForm.title);
  //   formData.append("Price", shootForm.price);
  //   formData.append("AdditionalInfo", shootForm.additionalInfo);
  //   formData.append("Overview", shootForm.overview);
  //   formData.append("TermCondition", shootForm.termCondition);
  //   formData.append("ShootCategoryId", shootForm.shootCategoryId);

  //   // Append multiple images
  //   shootForm.images.forEach(image => {
  //     formData.append("imageFiles", image);
  //   });

  //   // Append existing images that are not deleted
  //   shootForm.existingImages.forEach((imagePath) => {
  //     formData.append("Images", imagePath);
  //   });

  //   try {
  //     if (shootForm.id) {
  //       await axios.put(`https://api.travoolah.com/api/Shoot/${shootForm.id}`, formData, {
  //         headers: { 'Content-Type': 'multipart/form-data' }
  //       });
  //     } else {
  //       await axios.post('https://api.travoolah.com/api/Shoot', formData, {
  //         headers: { 'Content-Type': 'multipart/form-data' }
  //       });
  //     }
  //     resetForm();
  //     fetchShoots();
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     if (error.response && error.response.data) {
  //       setErrors(error.response.data.errors || {});
  //       alert(`Error: ${JSON.stringify(error.response.data)}`);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Reset errors
    const formData = new FormData();

    if (shootForm.id) {
        formData.append("Id", shootForm.id);
    }
    formData.append("Title", shootForm.title);
    formData.append("Price", shootForm.price);
    formData.append("AdditionalInfo", shootForm.additionalInfo);
    formData.append("Overview", shootForm.overview);
    formData.append("TermCondition", shootForm.termCondition);
    formData.append("ShootCategoryId", shootForm.shootCategoryId); // Ensure correct category is added

    // Append multiple images
    shootForm.images.forEach(image => {
        formData.append("imageFiles", image);
    });

    // Append existing images that are not deleted
    shootForm.existingImages.forEach((imagePath) => {
        formData.append("Images", imagePath);
    });

    try {
        if (shootForm.id) {
            await axios.put(`https://api.travoolah.com/api/Shoot/${shootForm.id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        } else {
            await axios.post('https://api.travoolah.com/api/Shoot', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        }
        resetForm();
        fetchShoots(); // Fetch updated shoots to reflect the new category
    } catch (error) {
        console.error("Error submitting form:", error);
        if (error.response && error.response.data) {
            setErrors(error.response.data.errors || {});
            alert(`Error: ${JSON.stringify(error.response.data)}`);
        }
    }
};


  // Handle edit action
  const handleEdit = (shoot) => {
    setShootForm({
      id: shoot.id,
      title: shoot.title || '',
      price: shoot.price || '',
      additionalInfo: shoot.additionalInfo || '',
      overview: shoot.overview || '',
      termCondition: shoot.termCondition || '',
      shootCategoryId: shoot.shootCategoryId || '',
      images: [],
      existingImages: shoot.images || []
    });
  };

  // Handle delete action
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this shoot package?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/Shoot/${id}`);
        fetchShoots();
      } catch (error) {
        console.error("Error deleting shoot package:", error);
      }
    }
  };

  // Reset form to initial state
  const resetForm = () => {
    setShootForm({
      id: null,
      images: [],
      existingImages: [],
      title: '',
      price: '',
      additionalInfo: '',
      overview: '',
      termCondition: '',
      shootCategoryId: ''
    });
    setErrors({});
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Photography Packages</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Existing Images */}
        {shootForm.existingImages.length > 0 && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Existing Images</label>
            <div className="flex flex-wrap gap-4">
              {shootForm.existingImages.map((imagePath, index) => (
                <div key={index} className="relative">
                  <img
                    src={`https://api.travoolah.com/${imagePath}`}
                    alt="Shoot"
                    className="w-32 h-32 object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(imagePath)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                  >
                    <XCircle size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="images">Upload Images</label>
          <input
            id="images"
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="mt-1 block w-full text-sm text-gray-500
                       file:mr-4 file:py-2 file:px-4
                       file:rounded-md file:border-0
                       file:text-sm file:font-semibold
                       file:bg-blue-50 file:text-blue-700
                       hover:file:bg-blue-100"
          />
          {errors.Images && <p className="text-red-500 text-sm mt-1">{errors.Images}</p>}
          <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>

        </div>

        {/* Title */}
        <div>
          <input
            type='text'
            name='title'
            value={shootForm.title}
            onChange={handleChange}
            placeholder='Title'
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {errors.Title && <p className="text-red-500 text-sm mt-1">{errors.Title}</p>}
        </div>

        {/* Price */}
        <div>
          <input
            type='number'
            name='price'
            value={shootForm.price}
            onChange={handleChange}
            placeholder='Price'
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {errors.Price && <p className="text-red-500 text-sm mt-1">{errors.Price}</p>}
        </div>

        {/* Overview */}
        <div>
          <textarea
            name="overview"
            value={shootForm.overview}
            onChange={handleChange}
            placeholder="Overview"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.Overview && <p className="text-red-500 text-sm mt-1">{errors.Overview}</p>}
        </div>

        {/* Additional Info */}
        <div>
          <textarea
            name="additionalInfo"
            value={shootForm.additionalInfo}
            onChange={handleChange}
            placeholder="Additional Information"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.AdditionalInfo && <p className="text-red-500 text-sm mt-1">{errors.AdditionalInfo}</p>}
        </div>

        {/* Terms and Conditions */}
        <div>
          <textarea
            name="termCondition"
            value={shootForm.termCondition}
            onChange={handleChange}
            placeholder="Terms and Conditions"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.TermCondition && <p className="text-red-500 text-sm mt-1">{errors.TermCondition}</p>}
        </div>

        {/* Category Select */}
        <div>
          <select
            name="shootCategoryId"
            value={shootForm.shootCategoryId}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select a category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
          {errors.ShootCategoryId && <p className="text-red-500 text-sm mt-1">{errors.ShootCategoryId}</p>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition duration-300 flex items-center justify-center"
        >
          <PlusCircle className="mr-2" size={20} />
          {shootForm.id ? 'Update Package' : 'Add Package'}
        </button>
      </form>

      {/* Existing Shoot Packages */}
      <h3 className='text-xl font-semibold mt-8 mb-4 text-gray-800'>Existing Photography Packages</h3>
      <ul className="divide-y divide-gray-200">
        {shoots.map(shoot => (
          <li key={shoot.id} className="py-4 flex justify-between items-center">
            <span className="text-lg text-gray-700">{shoot.title}</span>
            <div>
              <button
                onClick={() => handleEdit(shoot)}
                className="bg-yellow-500 text-white rounded-md p-2 mr-2 hover:bg-yellow-600 transition duration-300"
              >
                <Edit2 size={18} />
              </button>
              <button
                onClick={() => handleDelete(shoot.id)}
                className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
              >
                <Trash2 size={18} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PhotographForm;
