import React , {useState , useEffect}from 'react'
import axios from 'axios';
import VehicleCards from '../Common/VehicleCards'

function CarsPackages() {
  
  const [bikeState , setBikeState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Transportation");
      // Assuming the data is an array, set it directly
      setBikeState(Array.isArray(response.data.$values)?response.data.$values:[])
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

  return (
    <div className='flex justify-center px-4 sm:px-5 py-8 sm:py-16'>
      <div className='w-full sm:w-11/12 md:w-10/12 flex flex-col gap-y-6 sm:gap-y-10 py-8 sm:py-14'>
        <span className='mx-auto text-center'>
          <h3 className='text-[#353535] text-2xl sm:text-3xl py-2 sm:py-3 font-semibold'>Rent a Cab</h3>
        </span>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>
            {/* <VehicleCards img={inova} title={"Innova"} AirCond={"Air Conditioned Car"} seats={"7"} LuggageSp={"2 large suitcase"}/> */}
            {/* <VehicleCards img={tempo} title={"Tempo Traveller"} address={"Air Conditioned Car"} seats={"7"} LuggageSp={"2 large suitcase"}/> */}
            {/* <VehicleCards img={verna} title={"Verna"} address={"Air Conditioned Car"} seats={"7"} LuggageSp={"2 large suitcase"}/> */}
            {/* <VehicleCards img={swift} title={"Swift"} address={"Air Conditioned Car"} seats={"7"} LuggageSp={"2 large suitcase"}/> */}
            {Array.isArray(bikeState) && bikeState
            .slice(0, 3) // Limit to the first three records directly
            .map((event, index) => (
              <VehicleCards 
              img={`https://api.travoolah.com/${event.image}`} // Adjust the image path if necessary
              title={event.title} 
              seats={event.seats} 
              LuggageSp={event.space} // Update logic for pricing as needed
            />
            ))}
        </div>
      </div>
    </div>
  )
}

export default CarsPackages