import React , {useState , useEffect}from 'react'
import { useNavigate } from "react-router-dom";
import WellnessCard from '../Common/WellnessCard';
import axios from 'axios';

function YogaRelaxation() {

  const [yogaState , setYogaState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Relaxation");
      // Assuming the data is an array, set it directly
      setYogaState(response.data.$values);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/wellness/${id}`)
    }

      return (
        <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
          <div className='w-full max-w-6xl flex flex-col gap-y-5'>
            <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>Yoga & Relaxation Sessions</h3>
            <div className='relative py-8'>
              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
       {/* <Card img={Lalit} title={"The Lalit Grand Palace"} address={"Gupkar Rd, Srinagar, J&K 190001"} oldPrice={"7999"} newPrice={"4999/Night"}/> */}
       {yogaState.length > 0 ? yogaState
              .filter((event) => event.relaxationCategoryId== "4") // Filter by category name
              .map((event, index) => (
                <div key={index} className='w-full'>
                  <WellnessCard
                    img={event.images && event.images.$values.length > 0 ? `https://api.travoolah.com/${event.images.$values[0]}` : '/placeholder-image.jpg'} // Fallback to placeholder
                    title={event.title} 
                    duration={event.duration}
                    oldPrice={`${event.price + 1500}`}
                    newPrice={event.price} 
                    address={event.description}
                    onClick={()=>{
                      handleCardClick(event.id)
                    }}
                  />
                </div>
              )) :  <p>No data avalible</p>}
              </div>
            </div>
          </div>
        </div>
      )
}

export default YogaRelaxation