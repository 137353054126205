import React, { useEffect } from 'react'
import AdventurePagetTopSec from '../Components/AdventurePage/AdventurePagetTopSec'
import AdventureActivities from '../Components/AdventurePage/AdventureActivities'
import WinterSports from '../Components/AdventurePage/WinterSports'
import OtherActivities from '../Components/AdventurePage/OtherActivities'
import { animateWithGsap } from '../Utils/gsapAnimations'

function AdventrePage() {
  useEffect(() => {
    animateWithGsap('.animationSection', 1, 0.5)
  },[])
  
  return (
    <div className="w-full overflow-x-hidden animationSection">
        <AdventurePagetTopSec />
        <AdventureActivities />
        <WinterSports />
        <OtherActivities />
    </div>
  )
}

export default AdventrePage