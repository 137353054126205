import React, { useState, useEffect } from 'react';
import VehicleCards from '../Common/VehicleCards';
import axios from 'axios';

function CabsRental() {
  const [otherRentals, setOtherRentalState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Transportation");
      // Assuming the data is an array, set it directly
      setOtherRentalState(response.data.$values);
      console.log(response);
    } catch (error) {
      console.error("Error fetching transportation data:", error);
    }
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    
       

        <div className='flex justify-center'>
          <div className='w-10/12 flex flex-col gap-y-2'>
            <span className='mx-auto'>
              <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Cabs</h3>
            </span>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
              {otherRentals.length > 0 ? otherRentals
                .filter((event) => event.transportationCategoryId == "2") // Filter by category
                .map((event, index) => (
                  <div key={index} className='w-full'>
                    <VehicleCards 
                      img={`https://api.travoolah.com/${event.image}`} // Image path adjusted
                      title={event.title} 
                      seats={event.seats} 
                      LuggageSp={event.space} // Update logic for pricing as needed
                    />
                  </div>
                )) : <p>No data available</p>}
            </div>
          </div>
        </div>
    
  );
}

export default CabsRental;
