import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './Contact'; // Import the contact form component

function WellnessDetailsPage() {
  const { id } = useParams();
  const [wellness, setWellness] = useState(null);
  const [showForm, setShowForm] = useState(false); // State to show/hide the form

  useEffect(() => {
    const fetchWellnessData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/Relaxation/${id}`);
        setWellness(response.data);
      } catch (error) {
        console.error("Error fetching wellness details:", error);
      }
    };
    fetchWellnessData();
  }, [id]);

  const closeForm = () => {
    setShowForm(false);
  }

  if (!wellness) return <p>Loading...</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Wellness Title and Booking Button */}
      <h1 className="text-4xl font-semibold text-center mt-11">{wellness.title}</h1>
      <div className="flex justify-center mt-4">
        <button
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600"
          onClick={() => setShowForm(true)} // Trigger form visibility
        >
          Book Now
        </button>
      </div>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {wellness.images && wellness.images.$values.length > 0 ? (
            wellness.images.$values.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>

      {/* Wellness Overview */}
      {wellness.overview && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Wellness Overview</h2>
          <p className="text-gray-700 mt-4 text-center">{wellness.overview}</p>
        </div>
      )}

      {/* Price */}
      <div className="mt-4 text-center">
        <p className="text-lg font-semibold">Price: ₹{wellness.price}</p>
      </div>

      {/* Additional Information */}
      {wellness.additionalInfo && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Additional Information</h2>
          <p className="text-gray-700 mt-4 text-center">{wellness.additionalInfo}</p>
        </div>
      )}

      {/* Terms and Conditions */}
      {wellness.termCondition && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Terms and Conditions</h2>
          <p className="text-gray-700 mt-4 text-center">{wellness.termCondition}</p>
        </div>
      )}


      {/* Category */}
      {/* {wellness.relaxationCategory && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-center">Category</h2>
          <p className="text-gray-700 mt-4 text-center">{wellness.relaxationCategory.name}</p>
        </div>
      )} */}

      {/* Show contact form when "Book Now" is clicked */}
      {showForm && (
        <ContactForm
          hotelId={id} // Pass wellness ID to the contact form
          hotelName={wellness.title} // Pass wellness title to the contact form
          onSuccess={() => setShowForm(false)} // Close form on success
          closeForm={() => setShowForm(false)} // Ensure closeForm toggles the state

        />
      )}
    </div>
  );
}

export default WellnessDetailsPage;
