import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ContactForm({ hotelName, hotelId, roomId, roomName, onSubmit }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        contactDate: new Date().toISOString(),
        numberOfChilds: 0,
        numberOfAdults: 0,
        numberOfDays: 0,
        startDate: '',
        endDate: '',
        hotelName: hotelName,
        hotelId: hotelId,
        roomId: roomId,
        roomName: roomName,
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission status

    useEffect(() => {
        if (formData.startDate && formData.endDate) {
            const start = new Date(formData.startDate);
            const end = new Date(formData.endDate);
            const diffTime = end - start;
            const diffDays = Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0); // Avoid negative values
            setFormData((prev) => ({ ...prev, numberOfDays: diffDays }));
        }
    }, [formData.startDate, formData.endDate]);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.email.trim()) newErrors.email = 'Email is required';
        if (!/^\S+@\S+\.\S+$/.test(formData.email)) newErrors.email = 'Invalid email format';
        if (!formData.phone.trim()) newErrors.phone = 'Phone is required';

        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!formData.startDate) newErrors.startDate = 'Start date is required';
        if (!formData.endDate) newErrors.endDate = 'End date is required';
        if (startDate < today) newErrors.startDate = 'Start date cannot be in the past';
        if (endDate <= startDate) newErrors.endDate = 'End date must be after start date';

        if (formData.numberOfAdults < 1) newErrors.numberOfAdults = 'At least one adult is required';
        if (formData.numberOfChilds < 0) newErrors.numberOfChilds = 'Number of children cannot be negative';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setIsSubmitting(true); // Set the loading state to true

        const submissionData = {
            ...formData,
            startDate: new Date(formData.startDate).toISOString(),
            endDate: new Date(formData.endDate).toISOString(),
            contactDate: new Date().toISOString(),
        };

        try {
            const response = await axios.post(
                'https://api.travoolah.com/api/HotelContact/contact',
                submissionData
            );
            onSubmit(response.data);
            alert('Booking inquiry submitted successfully!');

            setFormData({
                name: '',
                email: '',
                phone: '',
                contactDate: new Date().toISOString(),
                numberOfChilds: 0,
                numberOfAdults: 0,
                numberOfDays: 0,
                startDate: '',
                endDate: '',
                hotelName: hotelName,
                hotelId: hotelId,
                roomId: roomId,
                roomName: roomName,
            });
        } catch (error) {
            console.error('Error submitting the form:', error);
            alert('Error submitting the form. Please try again.');
        } finally {
            setIsSubmitting(false); // Reset the loading state
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="space-y-4 max-w-4xl mx-auto px-4 py-6 relative"
        >
            <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1" htmlFor="name">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="border rounded-md p-2 w-full"
                        placeholder="Your Name"
                    />
                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                </div>
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="border rounded-md p-2 w-full"
                        placeholder="Your Email"
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium mb-1" htmlFor="phone">
                    Phone
                </label>
                <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="border rounded-md p-2 w-full"
                    placeholder="Your Phone Number"
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1" htmlFor="startDate">
                        Start Date
                    </label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        className="border rounded-md p-2 w-full"
                    />
                    {errors.startDate && <p className="text-red-500 text-sm">{errors.startDate}</p>}
                </div>
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1" htmlFor="endDate">
                        End Date
                    </label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        className="border rounded-md p-2 w-full"
                    />
                    {errors.endDate && <p className="text-red-500 text-sm">{errors.endDate}</p>}
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1" htmlFor="numberOfAdults">
                        Number of Adults
                    </label>
                    <input
                        type="number"
                        id="numberOfAdults"
                        name="numberOfAdults"
                        value={formData.numberOfAdults}
                        onChange={handleChange}
                        className="border rounded-md p-2 w-full"
                        min="1"
                    />
                    {errors.numberOfAdults && <p className="text-red-500 text-sm">{errors.numberOfAdults}</p>}
                </div>
                <div className="flex-1">
                    <label className="block text-sm font-medium mb-1" htmlFor="numberOfChilds">
                        Number of Children
                    </label>
                    <input
                        type="number"
                        id="numberOfChilds"
                        name="numberOfChilds"
                        value={formData.numberOfChilds}
                        onChange={handleChange}
                        className="border rounded-md p-2 w-full"
                        min="0"
                    />
                    {errors.numberOfChilds && <p className="text-red-500 text-sm">{errors.numberOfChilds}</p>}
                </div>
            </div>

            <div>
                <button
                    type="submit"
                    className="bg-orange-500 text-white  px-4 py-2 rounded-md hover:bg-blue-600"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit Booking Inquiry'}
                </button>
            </div>
        </form>
    );
}

export default ContactForm;
