import React, { useEffect } from 'react'
import Slider from '../Components/Common/Slider'
import Property1 from "../Assets/Property1.jpg";
import Property2 from "../Assets/Property2.jpg";
import Property3 from "../Assets/Property3.jpg";
import Property4 from "../Assets/Property4.jpg";
import Property5 from "../Assets/Property5.jpg";
import Property6 from "../Assets/Property6.jpg";
import Property7 from "../Assets/Property7.jpg";
import Property8 from "../Assets/Property8.jpg";
import Hotels from '../Components/Travelpackage/Hotels';
import LuxuryTravel from '../Components/Travelpackage/LuxuryTravel';
import OtherTravelPackages from '../Components/Travelpackage/OtherTravelPackages';
import { animateWithGsap } from '../Utils/gsapAnimations';

function TravelPackagesPage() {
  const herSectionImages = [Property1 , Property2 , Property3 , Property4 , Property5 , Property6 , Property7 , Property8];

  //useEffect for animations
  useEffect(() => {
    animateWithGsap(".mainAnimationSection",1.5,0.5)
  }, [])
  

  return (
    <div className='relative pt-16 flex flex-col items-center mainAnimationSection'>
        <div className='relative w-full overflow-hidden z-20'>
            <Slider images={herSectionImages} height={"h-[60vh]"}/>
        </div>
        {/* <HotelPageInput /> */}
        {/* Customizable Travel Itineraries */}
        <Hotels />

        {/* luxury Travel */}
        <LuxuryTravel />

        {/* other travel Packages */}
        <OtherTravelPackages />
    </div>
  )
}

export default TravelPackagesPage