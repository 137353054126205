import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
              
              <p className="text-sm text-gray-600 mb-4">
                <strong>Effective Date:</strong> June 1, 2023<br />
                <strong>Last Updated:</strong> June 1, 2023
              </p>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Introduction</h2>
                <p className="text-gray-700 mb-4">
                  At <strong className="text-indigo-600">Travoolah</strong>, we are committed to protecting the privacy and
                  security of our users' personal information. This Privacy Policy outlines how we collect, use, share,
                  and safeguard your personal information when you access our services via our website, mobile
                  applications, and other online platforms.
                </p>
                <p className="text-gray-700 mb-4">
                  We respect your privacy and are dedicated to ensuring that your personal data is handled with the
                  highest standards of security and confidentiality, in compliance with Indian law, including the
                  <strong> Information Technology Act, 2000</strong> and other applicable data protection regulations.
                </p>
                <p className="text-gray-700">
                  By using Travoolah's services, you agree to the terms outlined in this policy.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Information We Collect</h2>
                <h3 className="text-xl font-medium text-gray-700 mb-2">2.1 Personal Information</h3>
                <p className="text-gray-700 mb-4">
                  We collect personal data necessary to provide our services, including but not limited to:
                </p>
                <ul className="list-disc list-inside text-gray-700 mb-4 ml-4">
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Contact number</li>
                  <li>Mailing and billing address</li>
                  <li>Payment information (credit/debit card details)</li>
                  <li>Identification documents (for services requiring identity verification, such as rentals)</li>
                </ul>
                <h3 className="text-xl font-medium text-gray-700 mb-2">2.2 Non-Personal Information</h3>
                <p className="text-gray-700 mb-4">
                  We also collect non-personal data automatically through cookies and similar technologies, which may include:
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>IP address</li>
                  <li>Browser type and version</li>
                  <li>Device information</li>
                  <li>Pages accessed on our website</li>
                  <li>Date and time of access</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. How We Use Your Information</h2>
                <p className="text-gray-700 mb-4">
                  We use the information collected to enhance and personalize your experience with Travoolah. This includes:
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>Processing bookings and reservations</li>
                  <li>Managing travel and accommodation arrangements</li>
                  <li>Providing customer support</li>
                  <li>Communicating updates and offers related to our services</li>
                  <li>Complying with legal obligations</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Sharing of Information</h2>
                <p className="text-gray-700 mb-4">
                  We do not sell your personal data to third parties. However, we may share your information in the following circumstances:
                </p>
                <h3 className="text-xl font-medium text-gray-700 mb-2">4.1 Service Providers</h3>
                <p className="text-gray-700 mb-4">
                  We may share your information with trusted third-party partners who assist in providing services, such as:
                </p>
                <ul className="list-disc list-inside text-gray-700 mb-4 ml-4">
                  <li>Payment processors for secure transaction handling</li>
                  <li>Hotel, villa, and service partners for accommodation bookings</li>
                  <li>Transportation partners for arranging travel logistics</li>
                </ul>
                <p className="text-gray-700 mb-4">
                  These partners are contractually bound to handle your data securely and only for the purpose of providing services requested by you.
                </p>
                <h3 className="text-xl font-medium text-gray-700 mb-2">4.2 Legal Compliance</h3>
                <p className="text-gray-700 mb-4">
                  We may disclose your personal data to:
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>Comply with legal obligations, court orders, or other legal processes</li>
                  <li>Enforce our terms of service</li>
                  <li>Protect the rights, property, and safety of Travoolah, our customers, or others</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Data Security</h2>
                <p className="text-gray-700 mb-4">
                  We use industry-standard security measures to protect your data, including:
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>Encryption for sensitive information (such as payment details)</li>
                  <li>Regular security assessments to protect against unauthorized access or misuse of data</li>
                  <li>Access controls ensuring only authorized personnel can view or process sensitive data</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Your Rights</h2>
                <p className="text-gray-700 mb-4">
                  As a user, you have the following rights regarding your data:
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li><strong>Access:</strong> Request a copy of the personal data we hold about you</li>
                  <li><strong>Correction:</strong> Ask for corrections to inaccurate or incomplete data</li>
                  <li><strong>Deletion:</strong> Request deletion of your personal data, subject to legal obligations</li>
                  <li><strong>Opt-out:</strong> Unsubscribe from marketing communications at any time</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Use of Cookies</h2>
                <p className="text-gray-700 mb-4">
                  Travoolah uses cookies to enhance user experience, analyze website traffic, and tailor advertisements. 
                  You may manage cookie preferences through your browser settings. Disabling cookies may affect the 
                  functionality of certain features on our website.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Data Retention</h2>
                <p className="text-gray-700 mb-4">
                  We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy, 
                  or as required by law. Data related to completed transactions may be retained for compliance with tax, 
                  legal, or regulatory requirements.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Third-Party Links</h2>
                <p className="text-gray-700 mb-4">
                  Our website may contain links to third-party websites. Travoolah is not responsible for the privacy 
                  practices or content of these external websites. We encourage you to review their privacy policies 
                  before providing any personal information.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Changes to This Privacy Policy</h2>
                <p className="text-gray-700 mb-4">
                  We may update this Privacy Policy from time to time. Changes will be posted on this page, and, where 
                  significant, we will notify users through our services. We encourage you to review this policy 
                  periodically for the latest information on our privacy practices.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Contact Us</h2>
                <p className="text-gray-700 mb-4">
                  If you have any questions or concerns regarding our Privacy Policy or the handling of your personal data, please contact us at:
                </p>
                <ul className="list-none text-gray-700 ml-4">
                  <li><strong>Email:</strong> official@travoolah.com</li>
                  <li><strong>Phone:</strong> +91 9469133665</li>
                  <li><strong>Address:</strong> Shaltang Chowk Srinagar 190017, India</li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      );
}

export default PrivacyPolicy