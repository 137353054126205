import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import gsap from 'gsap'; // Import GSAP
import ContactForm from './HotelContact';
import Slider from '../Components/Common/Slider';
import HomepageLoader from './HomepageLoader';

function HotelDetailsPage() {
  const { id } = useParams();
  const [hotel, setHotel] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  

  // Fetch hotel details
  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        const response = await axios.get(`https://api.travoolah.com/api/Hotel/${id}`);
        setHotel(response.data);
        setRooms(response.data.hotelRooms?.$values || []);
      } catch (error) {
        console.error('Error fetching hotel details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotelData();
  }, [id]);

  // GSAP animation for the form
  useEffect(() => {
    if (showForm) {
      gsap.fromTo(
        '.formGsapClass',
        { opacity: 0 },
        { opacity: 1, duration: 1.5, delay: 0.5 }
      );
    }
  }, [showForm]);

  const handleBookNowClick = (room = null) => {
    setSelectedRoom(room);
    setShowForm(true);
    setTimeout(() => {
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleFormSubmit = (data) => {
    setLoading(true); // Start loader for form submission
    // console.log('Form submitted:', data);
  
    // Simulate form submission time
    setTimeout(() => {
      alert('Form submitted successfully!');
      setLoading(false); // Stop loader after submission
    }, 2000);
  };
  

  if (loading) {
    return <HomepageLoader />;
  }

  if (!hotel) {
    return <p>Loading...</p>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 mt-20 py-7">
      {/* Header Section */}
      <header className="text-center">
        <h1 className="text-4xl font-bold mb-4">{hotel.title}</h1>
        {/* <button
          onClick={() => handleBookNowClick()}
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600">
          Book Now
        </button> */}
      </header>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {hotel.images?.$values?.length > 0 ? (
            hotel.images.$values.map((image, index) => (
              <img
                key={index}
                src={`https://api.travoolah.com/${image}`}
                alt={`Hotel Image ${index + 1}`}
                className="w-full h-full object-cover rounded"
              />
            ))
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>

      {/* Rooms Section */}
      <section className="mt-10">
        <h2 className="text-3xl font-semibold text-center">Rooms</h2>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {rooms.length > 0 ? (
            rooms.map((room, index) => (
              <div
                key={index}
                className="text-center border rounded-md shadow-md pb-6 hover:shadow-lg transition-shadow">
                {/* Room Images Slider */}
                {room.images?.$values?.length > 0 ? (
                  <Slider
                    images={room.images.$values.map(img => `https://api.travoolah.com/hotelrooms/${img}`)}
                    height="h-40"
                  />
                ) : (
                  <p className="text-gray-500">No images available</p>
                )}

                <p className="mt-2 text-gray-700 font-bold">{room.name}</p>
                <p className="text-gray-500 text-sm">Capacity: {room.capacity}</p>
                <p className="text-gray-500 text-sm">Price: ₹{room.pricePerNight}</p>

                <button
                  className="mt-4 bg-orange-500 text-white  py-2 px-4 rounded hover:bg-orange-600"
                  onClick={() => handleBookNowClick(room)}>
                  Book Now
                </button>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500 col-span-full">No rooms available</p>
          )}
        </div>
      </section>

      {/* Description Section */}
      <section className="mt-10">
        {hotel.overview && (
          <div className="py-5">
            <h2 className="text-center text-xl font-bold">Description</h2>
            <p className="text-center text-gray-700 mt-2">{hotel.overview}</p>
          </div>
        )}
        {hotel.price && (
          <p className="text-center text-lg font-semibold mt-4">
            Price: ₹{hotel.price} <span className="text-gray-500 text-xs">/Per Night</span>
          </p>
        )}
         {hotel.description && (
  <p className="text-center text-lg font-semibold mt-4">
    Location:{" "}
    <a
      href={`https://www.google.com/maps/search/?q=${encodeURIComponent(hotel.description)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:underline"
    >
      {hotel.description}
    </a>
    <span className="text-gray-500 text-xs"></span>
  </p>
)}

      </section>

      
{/* Services Section */}
{hotel.services.$values?.length > 0 && (
  <section className="mt-12">
    <h2 className="text-3xl font-semibold text-center">Services</h2>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-6">
      {hotel.services.$values.map((service, index) => (
        <div key={index} className="text-center border rounded-md px-3 py-6">
          {service.icon && (
            <img
              src={`https://api.travoolah.com/images/${service.icon}`} // Combine base URL and icon path
              alt={service.title}
              className="w-16 h-16 object-cover mx-auto mb-4" // Adjust size as needed
            />
          )}
          <p className="text-gray-700 font-bold">{service.title}</p>
          <p className="text-gray-500 text-sm">{service.description}</p>
        </div>
      ))}
    </div>
  </section>
)}



      {/* Additional Information */}
      {hotel.additionalInfo && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Additional Information</h2>
          <p className="text-center text-gray-700 mt-4">{hotel.additionalInfo}</p>
        </section>
      )}

      {/* Terms & Conditions */}
      {hotel.termCondition && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Terms & Conditions</h2>
          <p className="text-center text-gray-700 mt-4">{hotel.termCondition}</p>
        </section>
      )}
      

      {/* Contact Form */}
      {showForm && (
        
        <section ref={formRef} className="mt-12 formGsapClass bg-gray-100 p-6 rounded shadow-lg">
          <h2 className="text-3xl font-semibold text-center">Contact Form</h2>
          <ContactForm
            hotelName={hotel.title}
            hotelId={hotel.id}
            roomName={selectedRoom?.name || ''}
            roomId={selectedRoom?.id || ''}
            onSubmit={handleFormSubmit}
          />
        </section>
        
      )}
    </div>


  );
}

export default HotelDetailsPage;




// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import ContactForm from './HotelContact';
// import Slider from '../Components/Common/Slider';
// import HomepageLoader from './HomepageLoader';

// function HotelDetailsPage() {
//   const { id } = useParams();
//   const [hotel, setHotel] = useState(null);
//   const [rooms, setRooms] = useState([]);
//   const [loading, setLoading] = useState(true); // Unified loading state
//   const [showForm, setShowForm] = useState(false);
//   const [selectedRoom, setSelectedRoom] = useState(null);
//   const formRef = useRef(null);

//   // Fetch hotel details
//   useEffect(() => {
//     const fetchHotelData = async () => {
//       try {
//         const response = await axios.get(`https://api.travoolah.com/api/Hotel/${id}`);
//         setHotel(response.data);
//         setRooms(response.data.hotelRooms?.$values || []);
//       } catch (error) {
//         console.error('Error fetching hotel details:', error);
//       } finally {
//         setLoading(false); // Stop loader after fetching
//       }
//     };

//     fetchHotelData();
//   }, [id]);

//   const handleBookNowClick = (room = null) => {
//     setSelectedRoom(room);
//     setShowForm(true);
//     setTimeout(() => {
//       formRef.current?.scrollIntoView({ behavior: 'smooth' });
//     }, 100);
//   };

//   const handleFormSubmit = (data) => {
//     setLoading(true); // Start loader for form submission
//     console.log('Form submitted:', data);
//     setTimeout(() => {
//       alert('Form submitted successfully!');
//       setLoading(false); // Stop loader after submission
//     }, 2000); // Simulate form submission time
//   };

//   if (loading) {
//     return <HomepageLoader />; // Display loader for both initial loading and form submission
//   }

//   return (
//     <div className="max-w-7xl mx-auto px-4 mt-20 py-7">
//       {/* Header Section */}
//       <header className="text-center">
//         <h1 className="text-4xl font-bold mb-4">{hotel.title}</h1>
//         <button
//           onClick={() => handleBookNowClick()}
//           className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600">
//           Book Now
//         </button>
//       </header>

//       {/* Image Gallery */}
//       <section className="mt-8">
//         <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
//           {hotel.images?.$values?.length > 0 ? (
//             hotel.images.$values.map((image, index) => (
//               <img
//                 key={index}
//                 src={`https://api.travoolah.com/${image}`}
//                 alt={`Hotel Image ${index + 1}`}
//                 className="w-full h-full object-cover rounded"
//               />
//             ))
//           ) : (
//             <p className="text-center text-gray-500 col-span-3">No images available</p>
//           )}
//         </div>
//       </section>

//       {/* Rooms Section */}
//       <section className="mt-10">
//         <h2 className="text-3xl font-semibold text-center">Rooms</h2>
//         <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//           {rooms.length > 0 ? (
//             rooms.map((room, index) => (
//               <div
//                 key={index}
//                 className="text-center border rounded-md shadow-md pb-6 hover:shadow-lg transition-shadow">
//                 {/* Room Images Slider */}
//                 {room.images?.$values?.length > 0 ? (
//                   <Slider
//                     images={room.images.$values.map(img => `https://api.travoolah.com/hotelrooms/${img}`)}
//                     height="h-40"
//                   />
//                 ) : (
//                   <p className="text-gray-500">No images available</p>
//                 )}

//                 <p className="mt-2 text-gray-700 font-bold">{room.name}</p>
//                 <p className="text-gray-500 text-sm">Capacity: {room.capacity}</p>
//                 <p className="text-gray-500 text-sm">Price: ₹{room.pricePerNight}</p>

//                 <button
//                   className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
//                   onClick={() => handleBookNowClick(room)}>
//                   Book Now
//                 </button>
//               </div>
//             ))
//           ) : (
//             <p className="text-center text-gray-500 col-span-full">No rooms available</p>
//           )}
//         </div>
//       </section>

//       {/* Contact Form */}
//       {showForm && (
//         <section ref={formRef} className="mt-12 bg-gray-100 p-6 rounded shadow-lg">
//           <h2 className="text-3xl font-semibold text-center">Contact Form</h2>
//           <ContactForm
//             hotelName={hotel.title}
//             hotelId={hotel.id}
//             roomName={selectedRoom?.name || ''}
//             roomId={selectedRoom?.id || ''}
//             onSubmit={handleFormSubmit}
//           />
//         </section>
//       )}
//     </div>
//   );
// }

// export default HotelDetailsPage
