import React, { useEffect } from 'react'
import CarRental from '../Components/Transportation/CarRental'
import BikeRentals from '../Components/Transportation/BikeRentals'
import CabsRental from '../Components/Transportation/CabsRental'
import { animateWithGsap } from '../Utils/gsapAnimations'


function TransportPage() {

  //useEffect for animations
  useEffect(() => {
    animateWithGsap('.animationSection', 1, 0.5)
  }, [])
  
  return (
    <div className='animationSection'>
        <CarRental />
        <BikeRentals />
        <CabsRental />
    </div>
  )
}

export default TransportPage