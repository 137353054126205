import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';  // Import WhatsApp icon from react-icons
import HeroSection from '../Components/HeroSection/HeroSection';
import HotelPackages from '../Components/HeroSection/HotelPackages';
import Villas from '../Components/HeroSection/Villas';
import CarsPackages from '../Components/HeroSection/CarsPackages';
import LetsTravel from '../Components/HeroSection/LetsTravel';
import HomepageLoader from './HomepageLoader';
import gsap from 'gsap';

function Homepage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (e.g., 2 seconds)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust time as needed

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  //useEffect for animations
  useEffect(() => {
    gsap.fromTo('#homeSection', { y: '100%', opacity: 0 }, { y: 0, opacity: 1, duration: 1.5, delay: 0.5 });
  }, [loading]);

  return (
    <>
      {loading ? (
        <HomepageLoader /> // Render the loader
      ) : (
        <section id='homeSection' className=''>
          <HeroSection />
          <HotelPackages />
          <Villas />
          <CarsPackages />
          <LetsTravel />
        </section>
      )}

      {/* WhatsApp Button Fixed at Bottom-Right */}
      <a
        href="https://wa.me/9469133665" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        {/* WhatsApp Icon from React Icons */}
        <FaWhatsapp />
      </a>

      {/* WhatsApp Button CSS */}
      <style jsx>{`
        .whatsapp-button {
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 1000;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #25d366;
          border-radius: 50%;
          padding: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        }

        .whatsapp-button svg {
          width: 50px;
          height: 50px;
          color: white;
        }

        .whatsapp-button:hover {
          background-color: #128c7e;
        }
      `}</style>
    </>
  );
}

export default Homepage;
