import axios from 'axios'
import React, { useState } from 'react'

function PartnersPage() {
    const [partnerState, setPartnerState] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        company: '',
        message: '',
        service: ''
    })

    const handleChange = (e) => {
        setPartnerState(prev => ({
            ...prev, [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.travoolah.com/api/partners', partnerState);
            
            alert("Form submitted successfully! We will get back to you.");
            setPartnerState({
                name: '',
                email: '',
                phone: '',
                address: '',
                company: '',
                message: '',
                service:''
            })
        } catch(e) {
            console.error('Error submitting form:', e);
            const errorMessage = e.response?.data?.message || 'Error submitting form. Please try again.';
            alert(errorMessage);
        }     
    }

    return (
        <div className='mt-20 flex flex-col py-5'>
            <h3 className='text-2xl font-bold text-center'>Become a Partner today</h3>
            <form className='w-4/5 mx-auto space-y-5 px-5' onSubmit={handleSubmit}>
                <div className='flex flex-col'>
                    <label htmlFor='name'>Name</label>
                    <input 
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Name'
                        className='border border-gray-500 rounded-md px-5 py-2'
                        value={partnerState.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='email'>Email</label>
                    <input 
                        type='email'
                        id='email'
                        name='email'
                        placeholder='john@gmail.com'
                        value={partnerState.email}
                        className='border border-gray-500 rounded-md px-5 py-2'
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='phone'>Phone</label>
                    <input 
                        type='tel'
                        id='phone'
                        name='phone'
                        placeholder='+91 555 555 5555'
                        value={partnerState.phone}
                        className='border border-gray-500 rounded-md px-5 py-2'
                        onChange={handleChange}
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='add'>Address</label>
                    <input 
                        type='text'
                        id='add'
                        name='address'
                        placeholder='j&k'
                        value={partnerState.address}
                        onChange={handleChange}
                        className='border border-gray-500 rounded-md px-5 py-2'
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='company'>Company</label>
                    <input 
                        type='text'
                        id='company'
                        name='company'
                        placeholder='Travoolah'
                        value={partnerState.company}
                        className='border border-gray-500 rounded-md px-5 py-2'
                        onChange={handleChange}
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='message'>Message</label>
                    <textarea 
                        id='message'
                        name='message'
                        placeholder='Hi, I want to be a partner'
                        value={partnerState.message}
                        className='border border-gray-500 rounded-md px-5 py-2'
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='message'>Service</label>
                    <textarea 
                        id='service'
                        name='service'
                        placeholder='Service'
                        value={partnerState.service}
                        className='border border-gray-500 rounded-md px-5 py-2'
                        onChange={handleChange}
                        rows="4"
                    />
                </div>

                <input
                    type='submit'
                    value='Become a Partner Now'
                    className='px-10 py-2 bg-[#F26222] text-white rounded-md hover:bg-[#A94214] transition duration-300 cursor-pointer'
                />
            </form>
        </div>
    )
}

export default PartnersPage