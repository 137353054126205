import React , {useState , useEffect}from 'react'
import VehicleCards from '../Common/VehicleCards'
import axios from 'axios';

function BikeRentals() {

  const [bikeState , setBikeState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Transportation");
      setBikeState(response.data.$values);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

  return (
    <div className='flex justify-center px-4 sm:px-5 mt-3 mb-5 sm:mt-20'>
        <div className='w-full max-w-6xl flex flex-col gap-y-5'>
            <span className='mx-auto'>
                <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Bike Rentals</h3>
            </span>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                {/* <VehicleCards img={Lalit} title={"The Lalit Grand Palace"} AirCond={"Gupkar Rd, Srinagar, J&K 190001"} seats={"7999"} LuggageSp={"4999/Night"}/> */}
                {bikeState.length > 0 ? bikeState
              .filter((event) => event.transportationCategoryId=="1") 
              .map((event, index) => (
                <div key={index} className='w-full'>
                  <VehicleCards 
                    img={`https://api.travoolah.com/${event.image}`}
                    title={event.title} 
                    seats={event.seats} 
                    LuggageSp={event.space}
                  />
                </div>
              )):  <p>No data avalible</p>}
            </div>
        </div>
    </div>
  )
}

export default BikeRentals