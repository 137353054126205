import React, { useEffect } from 'react';
import hotel1 from "../Assets/hotel1.jpg";
import hotel2 from "../Assets/hotel2.jpg";
import hotel3 from "../Assets/hotel3.jpg";
import hotel4 from "../Assets/hotel4.jpg";
import hotel5 from "../Assets/hotle5.jpg";
import Slider from '../Components/Common/Slider';
import HotelPage from '../Components/Hotels/HotelPage';
import { animateWithGsap } from '../Utils/gsapAnimations';


function HotelsPage() {
  const HotelImages = [hotel1, hotel2, hotel3, hotel4, hotel5];

  useEffect(() => {
    animateWithGsap(".mainAnimationSection",1.5,0.5);
  },[]);

  return (
    <div className="relative pt-16 flex flex-col items-center mainAnimationSection">
      <div className="relative w-full overflow-hidden z-20">
        <Slider images={HotelImages} height={"h-[60vh]"} />
      </div>
      <HotelPage/>
    </div>
  );
}

export default HotelsPage;
