import React, {useState , useEffect}  from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import EventCard from '../Common/EventCard';

function CorporateEvent() {

  const [corporateEvent , setCorporateEvent] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/EventManagement");
      // Assuming the data is an array, set it directly
      // console.log("cor" , response)
      setCorporateEvent(response.data.$values);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (id) => {
      navigate(`/eventManagement/${id}`)
  }


    return (
      <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
        <div className='w-full max-w-6xl flex flex-col gap-y-5'>
          <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>Corporate Event Management</h3>
          <div className='relative py-8'>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
            {corporateEvent.length > 0 ? corporateEvent
              .filter((event) => event.eventManagementCategoryId
              === 1) // Filter by category id if needed
              .map((adventure, index) => (
                <div key={index} className='w-full'>
                  <EventCard
                    img={adventure.images && adventure.images.$values.length > 0 ? `https://api.travoolah.com/${adventure.images.$values[0]}` : '/placeholder-image.jpg'} // Fallback to placeholder
                    title={adventure.title} 
                    oldPrice={`${adventure.price + 1500}`}
                    newPrice={adventure.price} 
                    onClick={()=>{
                      handleCardClick(adventure.id)
                    }}
                  />
                </div>
              )) :  <p>No data avalible</p>}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CorporateEvent