import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';

function EventManagementForm() {
  const [categories, setCategories] = useState([]);
  const [eventManagements, setEventManagements] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    images: [],
    existingImages: [],
    title: '',
    description: '',
    price: '',
    eventManagementCategoryId: ''
  });
  const [errors, setErrors] = useState({});

  // Fetch categories and event management packages
  useEffect(() => {
    fetchCategories();
    fetchEventManagements();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/EventManagementCategory');
      const categories = response.data.$values && response.data.$values ? response.data.$values : [];
      setCategories(categories);
    } catch (e) {
      console.error("Error fetching categories:", e);
    }
  };
  

  const fetchEventManagements = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/EventManagement');
      setEventManagements(Array.isArray(response.data.$values)?response.data.$values:[]);
    } catch (e) {
      console.error("Error fetching event managements:", e);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle multiple image uploads
  const handleImageChange = (e) => {
    setFormData({ ...formData, images: Array.from(e.target.files) });
  };

  // Handle existing image deletion
  const handleImageDelete = (imagePath) => {
    setFormData((prev) => ({
      ...prev,
      existingImages: prev.existingImages.filter((img) => img !== imagePath),
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    const submitData = new FormData();

    if (formData.id) {
      submitData.append("Id", formData.id);
    }
    submitData.append("Title", formData.title);
    submitData.append("Description", formData.description);
    submitData.append("Price", formData.price);
    submitData.append("EventManagementCategoryId", formData.eventManagementCategoryId);

    // Append images
    formData.images.forEach(image => {
      submitData.append("imageFiles", image);
    });

    // Append existing images
    formData.existingImages.forEach((imagePath) => {
      submitData.append("Images", imagePath);
    });

    try {
      if (formData.id) {
        await axios.put(`https://api.travoolah.com/api/EventManagement/${formData.id}`, submitData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://api.travoolah.com/api/EventManagement', submitData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      resetForm();
      fetchEventManagements();
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors || {});
        alert(`Error: ${JSON.stringify(error.response.data)}`);
      }
    }
  };

  // Handle edit action
  const handleEdit = (eventManagement) => {
    setFormData({
      id: eventManagement.id,
      title: eventManagement.title || '',
      description: eventManagement.description || '',
      price: eventManagement.price || '',
      eventManagementCategoryId: eventManagement.eventManagementCategoryId || '',
      images: [],
      existingImages: eventManagement.images || []
    });
  };

  // Handle delete action
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this event management package?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/EventManagement/${id}`);
        fetchEventManagements();
      } catch (error) {
        console.error("Error deleting package:", error);
      }
    }
  };

  // Reset form to initial state
  const resetForm = () => {
    setFormData({
      id: null,
      images: [],
      existingImages: [],
      title: '',
      description: '',
      price: '',
      eventManagementCategoryId: ''
    });
    setErrors({});
  };

  // Handle manage related entities (PackageIncludes, DayWiseItineraries, Addons)
  const handleManage = (entity, eventManagementId) => {
    // Navigate to the respective form/component for managing the entity
    // For simplicity, we can use window.location.href, or use a routing library like react-router
    window.location.href = `/${entity}/${eventManagementId}`;
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Event Management Form</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Existing Images */}
        {formData.existingImages.length > 0 && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Existing Images</label>
            <div className="flex flex-wrap gap-4">
              {formData.existingImages.map((imagePath, index) => (
                <div key={index} className="relative">
                  <img
                    src={`https://api.travoolah.com/${imagePath}`}
                    alt="Event"
                    className="w-32 h-32 object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(imagePath)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="images">Upload Images</label>
          <input
            id="images"
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="mt-1 block w-full text-sm text-gray-500
                       file:mr-4 file:py-2 file:px-4
                       file:rounded-md file:border-0
                       file:text-sm file:font-semibold
                       file:bg-blue-50 file:text-blue-700
                       hover:file:bg-blue-100"
          />
          {errors.Images && <p className="text-red-500 text-sm mt-1">{errors.Images}</p>}
          <span className='text-xs text-gray-500 font-bold'>Max 6 images</span>

        </div>

        {/* Title */}
        <div>
          <input
            type='text'
            name='title'
            value={formData.title}
            onChange={handleChange}
            placeholder='Title'
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {errors.Title && <p className="text-red-500 text-sm mt-1">{errors.Title}</p>}
        </div>

        {/* Description */}
        <div>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            className="w-full p-2 border border-gray-300 rounded-md h-24"
          />
          {errors.Description && <p className="text-red-500 text-sm mt-1">{errors.Description}</p>}
        </div>

        {/* Price */}
        <div>
          <input
            type='number'
            name='price'
            value={formData.price}
            onChange={handleChange}
            placeholder='Price'
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {errors.Price && <p className="text-red-500 text-sm mt-1">{errors.Price}</p>}
        </div>

        {/* Category Select */}
        <div>
          <select
            name="eventManagementCategoryId"
            value={formData.eventManagementCategoryId}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select a category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
          {errors.EventManagementCategoryId && <p className="text-red-500 text-sm mt-1">{errors.EventManagementCategoryId}</p>}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 transition duration-300 flex items-center justify-center"
        >
          <PlusCircle className="mr-2" size={20} />
          {formData.id ? 'Update Package' : 'Add Package'}
        </button>
      </form>

      {/* Existing Event Management Packages */}
      <h3 className='text-xl font-semibold mt-8 mb-4 text-gray-800'>Existing Event Management Packages</h3>
      <ul className="divide-y divide-gray-200">
        {eventManagements.map(pkg => (
          <li key={pkg.id} className="py-4 flex justify-between items-center">
            <span className="text-lg text-gray-700">{pkg.title}</span>
            <div className="space-x-2">
              <button
                onClick={() => handleEdit(pkg)}
                className="bg-yellow-500 text-white rounded-md p-2 hover:bg-yellow-600 transition duration-300"
              >
                <Edit2 size={18} />
              </button>
              <button
                onClick={() => handleDelete(pkg.id)}
                className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
              >
                <Trash2 size={18} />
              </button>
              {/* <button
                onClick={() => handleManage('packageincludes', pkg.id)}
                className="bg-green-500 text-white rounded-md p-2 hover:bg-green-600 transition duration-300"
              >
                Manage Package Includes
              </button>
              <button
                onClick={() => handleManage('daywiseitineraries', pkg.id)}
                className="bg-green-500 text-white rounded-md p-2 hover:bg-green-600 transition duration-300"
              >
                Manage Day-wise Itineraries
              </button>
              <button
                onClick={() => handleManage('addons', pkg.id)}
                className="bg-green-500 text-white rounded-md p-2 hover:bg-green-600 transition duration-300"
              >
                Manage Addons
              </button> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EventManagementForm;
