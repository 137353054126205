import React from 'react'

const HomepageLoader = () => {
  return (
    <section className='flex flex-col items-center justify-center h-screen'>
       
        <div class="spinner"></div>

        
    </section>
  )
}

export default HomepageLoader