import React, { useEffect } from 'react'
import Slider from '../Components/Common/Slider'
import villa1 from "../Assets/villa1.jpg"
import villa2 from "../Assets/villa2.jpg"
import villa3 from "../Assets/villa3.jpg"

import VillasPage from '../Components/Villas/VillasPage'
import gsap from 'gsap'

function Villas() {
  const villasImage = [villa1, villa2, villa3];
  // using useEffect
  useEffect(() => {
    gsap.fromTo('#homeSection', { y: '100%', opacity: 0 }, { y: 0, opacity: 1, duration: 1.5, delay: 0.5 });
   
  }, [])
  return (
    <div className="relative pt-16 flex flex-col items-center " id='homeSection'>
      <div className="relative w-full overflow-hidden z-20">
        <Slider images={villasImage} height={"h-[60vh]"} />
      </div>
      <VillasPage />
    </div>
  )
}

export default Villas