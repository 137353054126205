import React, { useState, useEffect } from "react";
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from "lucide-react";

function ServicesAdmin() {
  const [services, setServices] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [serviceForm, setServiceForm] = useState({
    id: null,
    title: "",
    description: "",
    icon: null,
  });

  // Fetch hotels
  const fetchHotels = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Hotel");
      setHotels(Array.isArray(response.data.$values) ? response.data.$values : []);
    } catch (error) {
      console.error("Error fetching hotels:", error);
    }
  };

  // Fetch services for the selected hotel
  const fetchServices = async () => {
    if (!selectedHotel) {
      setServices([]);
      return;
    }
    try {
      const response = await axios.get(
        `https://api.travoolah.com/api/Services?hotelId=${selectedHotel}`
      );
      setServices(response.data.$values);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  useEffect(() => {
    fetchServices();
  }, [selectedHotel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setServiceForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setServiceForm((prev) => ({ ...prev, icon: e.target.files[0] }));
  };

  const handleHotelChange = (e) => {
    setSelectedHotel(e.target.value);
    setServiceForm({ id: null, title: "", description: "", icon: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedHotel) {
      alert("Please select a hotel first.");
      return;
    }
    setLoading(true); // Set loading state to true
    try {
      const formData = new FormData();
      formData.append("id", serviceForm.id || "");
      formData.append("title", serviceForm.title);
      formData.append("description", serviceForm.description);
      formData.append("hotelId", selectedHotel);
      if (serviceForm.icon) {
        formData.append("icon", serviceForm.icon);
      }

      if (serviceForm.id) {
        await axios.put(
          `https://api.travoolah.com/api/Services/${serviceForm.id}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
      } else {
        await axios.post("https://api.travoolah.com/api/Services", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      resetForm();
      fetchServices();
    } catch (error) {
      console.error("Error submitting service:", error);
      alert("Failed to submit the service. Please try again.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleEdit = (service) => {
    setServiceForm({
      id: service.id,
      title: service.title,
      description: service.description,
      icon: null,
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/Services/${id}`);
        fetchServices();
      } catch (error) {
        console.error("Error deleting service:", error);
        alert("Failed to delete the service. Please try again.");
      }
    }
  };

  const resetForm = () => {
    setServiceForm({
      id: null,
      title: "",
      description: "",
      icon: null,
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Services</h2>

      <div className="mb-6">
        <label htmlFor="hotel-select" className="block text-gray-700 font-medium mb-2">
          Select Hotel
        </label>
        <select
          id="hotel-select"
          value={selectedHotel}
          onChange={handleHotelChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select a Hotel --</option>
          {hotels.map((hotel) => (
            <option key={hotel.id} value={hotel.id}>
              {hotel.title}
            </option>
          ))}
        </select>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="title"
          value={serviceForm.title}
          onChange={handleChange}
          placeholder="Service Title"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <textarea
          name="description"
          value={serviceForm.description}
          onChange={handleChange}
          placeholder="Service Description"
          className="w-full p-2 border border-gray-300 rounded-md"
        ></textarea>
        <input
          type="file"
          name="icon"
          onChange={handleFileChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700"
          disabled={loading}
        >
          {loading ? "Submitting..." : serviceForm.id ? "Update Service" : "Add Service"}
        </button>
        <button
          type="button"
          onClick={resetForm}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
        >
          Reset
        </button>
      </form>

      <div className="mt-8">
        {services.map((service) => (
          <div
            key={service.id}
            className="flex justify-between items-center p-4 border border-gray-300 rounded-md mb-4"
          >
            <div>
              <h3 className="font-bold text-gray-800">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
              {service.icon && (
                <img
                  src={service.icon}
                  alt={service.title}
                  className="mt-2 w-16 h-16 object-cover"
                />
              )}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(service)}
                className="text-yellow-600 hover:text-yellow-800"
              >
                <Edit2 size={20} />
              </button>
              <button
                onClick={() => handleDelete(service.id)}
                className="text-red-600 hover:text-red-800"
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesAdmin;
