import React from 'react'
import EventManagementTopSec from '../Components/EventPageComponents/EventManagementTopSec'
import DestinationWedding from '../Components/EventPageComponents/DestinationWedding'
import CorporateEvent from '../Components/EventPageComponents/CorporateEvent'
import ThemeParties from '../Components/EventPageComponents/ThemeParties'
import { useGSAP } from '@gsap/react'
import { animateWithGsap } from '../Utils/gsapAnimations'

function EventManagementPage() {

  useGSAP(()=>{
    animateWithGsap(".mainEventSection",1.5,0.5);
  },[])
  return (
    <div className="w-full overflow-x-hidden mainEventSection">
        <EventManagementTopSec />
        <DestinationWedding />
        <CorporateEvent />
        <ThemeParties />
    </div>
  )
}

export default EventManagementPage