import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Plus, Trash2 } from 'lucide-react';

function AddonsForm() {
  const [eventManagements, setEventManagements] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [addons, setAddons] = useState([]);
  const [newAddon, setNewAddon] = useState({
    title: '',
    description: ''
  });

  useEffect(() => {
    fetchEventManagements();
  }, []);

  useEffect(() => {
    if (selectedEventId) {
      fetchAddons();
    } else {
      setAddons([]);
    }
  }, [selectedEventId]);

  const fetchEventManagements = async () => {
    try {
      const response = await axios.get('https://api.travoolah.com/api/EventManagement');
      setEventManagements(Array.isArray(response.data.$values) ? response.data.$values : []);
    } catch (error) {
      console.error("Error fetching event management packages:", error);
    }
  };

  const fetchAddons = async () => {
    try {
      const response = await axios.get(`https://api.travoolah.com/api/EventmanagementAddons?eventManagementId=${selectedEventId}`);
      setAddons(response.data.$values);
    } catch (error) {
      console.error("Error fetching addons:", error);
    }
  };

  const handleAddAddon = async () => {
    if (!selectedEventId) {
      alert("Please select an event management package first.");
      return;
    }

    // Check if title and description are filled
    if (!newAddon.title.trim() || !newAddon.description.trim()) {
      alert("Please fill in both title and description.");
      return;
    }

    const payload = {
      title: newAddon.title,
      description: newAddon.description,
      eventManagementId: parseInt(selectedEventId)
    };
    try {
      const response = await axios.post('https://api.travoolah.com/api/EventmanagementAddons', payload);
      setAddons([...addons, response.data]);
      setNewAddon({ title: '', description: '' });
    } catch (error) {
      console.error("Error adding addon:", error);
    }
  };

  const handleDeleteAddon = async (id) => {
    if (window.confirm("Are you sure you want to delete this addon?")) {
      try {
        await axios.delete(`https://api.travoolah.com/api/EventmanagementAddons/${id}`);
        setAddons(addons.filter(a => a.id !== id));
      } catch (error) {
        console.error("Error deleting addon:", error);
      }
    }
  };

  const handleEventSelect = (e) => {
    setSelectedEventId(e.target.value);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manage Addons</h2>

      {/* Event Selection Dropdown */}
      <div className="mb-6">
        <label htmlFor="eventManagementId" className="block text-gray-700 font-medium mb-2">
          Select Event Management Package:
        </label>
        <select
          id="eventManagementId"
          value={selectedEventId}
          onChange={handleEventSelect}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">-- Select an Event Management Package --</option>
          {eventManagements.map(event => (
            <option key={event.id} value={event.id}>
              {event.title}
            </option>
          ))}
        </select>
      </div>

      {selectedEventId && (
        <>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Add New Addon</h3>
            <input
              type="text"
              value={newAddon.title}
              onChange={(e) => setNewAddon({ ...newAddon, title: e.target.value })}
              placeholder="Title"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <textarea
              value={newAddon.description}
              onChange={(e) => setNewAddon({ ...newAddon, description: e.target.value })}
              placeholder="Description"
              className="w-full p-2 border border-gray-300 rounded-md mb-2"
            />
            <button
              onClick={handleAddAddon}
              className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
            >
              <Plus size={20} className="mr-1" />
              Add Addon
            </button>
          </div>
          <h3 className="text-lg font-semibold mb-3">Existing Addons</h3>
          <ul className="divide-y divide-gray-200">
            {addons.map((addon) => (
              <li key={addon.id} className="py-4 flex justify-between items-center">
                <div>
                  <p className="font-medium">{addon.title}</p>
                  <p className="text-sm text-gray-600">{addon.description}</p>
                </div>
                <button
                  onClick={() => handleDeleteAddon(addon.id)}
                  className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300"
                >
                  <Trash2 size={18} />
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default AddonsForm;
