import { toast } from 'react-toastify';

// Function to show a success toast
const showSuccess = (message = "Operation successful!") => {
  toast.success(message);
};

// Function to show an error toast
const showError = (message = "Something went wrong!") => {
  toast.error(message);
};

// Function to show an info toast
const showInfo = (message = "Here is some information!") => {
  toast.info(message);
};

// Export the functions
export { showSuccess, showError, showInfo };
