import React, { useState, useEffect } from 'react';
import Vilas from "../../Assets/villas.jpg";
import axios from 'axios';
import AdventureCard from "../Common/AdventureCard";
import { useNavigate } from "react-router-dom";

function Villas() {
  const [adventure, setAdventure] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Adventure");
      // console.log("API Response:", response.data);
      // Adjust based on actual API structure
      setAdventure(Array.isArray(response.data.$values) ? response.data.$values : []);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/adventure/${id}`);
  };

  return (
    <div className='relative w-full min-h-screen flex flex-col items-center'>
      <div 
        className='absolute inset-0 bg-cover bg-center bg-no-repeat'
        style={{ backgroundImage: `url(${Vilas})` }}
      ></div>
      <div className='absolute inset-0 bg-black bg-opacity-50'></div>
      <div className='relative z-10 w-full min-h-screen flex items-center justify-center py-20'>
        <div className='w-11/12 max-w-7xl flex flex-col gap-y-8'>
          <h3 className='text-white font-semibold text-2xl sm:text-3xl md:text-4xl text-center px-4'>
            Our Adventure Packages
          </h3>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>
            {Array.isArray(adventure) && adventure.slice(0, 3).map((event, index) => (
              <AdventureCard 
                key={index}
                img={event.images && event.images.$values.length > 0 ? `https://api.travoolah.com/${event.images.$values[0]}` : '/placeholder-image.jpg'}
                title={event.title}
                location={event.location}
                oldPrice={`${event.price + 1500}`}
                newPrice={event.price}
                onClick={() => handleCardClick(event.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Villas;
