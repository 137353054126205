import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContactForm from './Contact'; // Import the ContactForm component
import HomepageLoader from './HomepageLoader';
import gsap from 'gsap';

function TravelDetailPage() {
  const { id } = useParams();
  const [packageData, setPackageData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchPackageData = async () => {
    try {
      const response = await axios.get(`https://api.travoolah.com/api/TravelPackage/${id}`);
      setPackageData(response.data);
      // console.log("Fetched package data: ", response.data);
    } catch (error) {
      console.error('Error fetching travel package details:', error);
    }
  };

  useEffect(() => {
    fetchPackageData();
    //try
    // Simulate loading time (e.g., 2 seconds)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust time as needed


    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [id]);

  useEffect(() => {
    gsap.fromTo('.gsapSectionTarget', {x:-100, opacity: 0 }, {x:0, opacity: 1, duration: 1.5, delay: 0.5 });
    
  }, [loading]);

  const closeForm = () => {
    setShowForm(false);
  }

  // useeffect for form animation 
  useEffect(() => {gsap.fromTo('.formGsapClass', { opacity: 0 }, { opacity: 1, duration: 1.5, delay: 0.5 });}, [showForm]);

  if (!packageData) return <p>Loading...</p>;

  return (

    <div className="max-w-7xl mx-auto px-4 mt-20 py-11 relative gsapSectionTarget">
      {loading ? (<HomepageLoader />) : (
        ""
      )}
      {/* Header Section */}
      <header className="text-center">
        <h1 className="text-4xl font-bold mb-4">{packageData.title}</h1>
        <button
          className="bg-orange-500 text-white py-2 px-6 rounded hover:bg-orange-600"
          onClick={() => setShowForm(true)} // Show the booking form on button click
          id='bookNowBtn'

        >
          Book Now
        </button>
      </header>

      {/* Image Gallery */}
      <section className="mt-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {packageData.images.$values && packageData.images.$values.length > 0 ? (
            packageData.images.$values.map((image, index) => {
              const imageClasses = [
                "col-span-2 row-span-2", // Larger image for img[0]
                "col-span-1 row-span-1", // Normal size for img[1]
                "col-span-1 row-span-1", // Normal size for img[2]
                "col-span-1 row-span-2", // Taller image for img[3]
                "col-span-1 row-span-1", // Normal size for img[4]
              ];
              return (
                <img
                  key={index}
                  src={`https://api.travoolah.com/${image}`}
                  alt={`Hotel Image ${index + 1}`}
                  className={`w-full h-full object-cover rounded ${imageClasses[index] || ''}`}
                />
              );
            })
          ) : (
            <p className="text-center text-gray-500 col-span-3">No images available</p>
          )}
        </div>
      </section>

      {/* Description Section */}
      <section className="mt-10">
        <p className="text-center mt-4 text-lg font-semibold">Duration: {packageData.duration}</p>
        <div className='flex flex-col py-5'>
          <p className='text-center mt-4 text-lg font-semibold'>Description</p>
          <p className="text-center text-gray-700">{packageData.overview}</p>
        </div>
        <p className="text-center text-lg font-semibold">Price: ₹{packageData.price}</p>
      </section>

      {/* Inclusions and Exclusions */}
      <section className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-bold mb-4">Inclusions</h2>
          {packageData.inclusions.$values && packageData.inclusions.$values.length > 0 ? (
            <ul className="list-disc ml-6">
              {packageData.inclusions.$values.map((item, index) => (
                <li key={index} className="text-gray-700">{item}</li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No inclusions available</p>
          )}
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Exclusions</h2>
          {packageData.exclusions.$values && packageData.exclusions.$values.length > 0 ? (
            <ul className="list-disc ml-6">
              {packageData.exclusions.$values.map((item, index) => (
                <li key={index} className="text-gray-700">{item}</li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No exclusions available</p>
          )}
        </div>
      </section>

      {/* Day Wise Itinerary */}
      {packageData.dayWiseItinerary.$values && packageData.dayWiseItinerary.$values.length > 0 && (
        <section className="mt-12">
          <h2 className="text-3xl font-semibold text-center">Day Wise Itinerary</h2>
          <div className="mt-6">
            {packageData.dayWiseItinerary.$values.map((itinerary, index) => (
              <div key={index} className="bg-white shadow-md rounded p-6 mt-4">
                <div className="flex items-center">
                  <div className="bg-orange-500 text-white w-12 h-12 flex items-center justify-center rounded-md text-lg font-semibold">
                    Day {itinerary.dayNumber}
                  </div>
                  <h3 className="text-lg font-semibold ml-4">{itinerary.title}</h3>
                </div>
                <p className="text-gray-700 mt-2 ml-16">{itinerary.details}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* Show contact form when "Book Now" is clicked */}
      {showForm && (
        <div className="formGsapClass">
          <ContactForm
            hotelId={id}
            hotelName={packageData.title}
            onSuccess={() => setShowForm(false)} // Close the form on success
            closeForm={() => setShowForm(false)} // Ensure closeForm toggles the state


          />
        </div>

      )}

      <p className="text-center mt-4 text-gray-600">Cancellation Policy: {packageData.cancellationPolicy}</p>

      <p className="text-center mt-4 text-gray-600">Payment Terms : {packageData.paymentTerms}</p>

    </div>
  );
}

export default TravelDetailPage;
